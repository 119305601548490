<template>
  <b-modal id="group-upload" hide-footer title="Upload Farmer Groups CSV">
    <div v-if="notification.status == 'unloaded'">
      <strong>CSV TEMPLATE</strong><br />
      <b-link @click="csv_template">Click to download template</b-link><br />
      <div class="my-2"></div>
      <strong>UPLOAD FARMER GROUPS</strong><br />
      <b-form @submit.prevent="upload_file" class="mt-1">
        <b-form-group>
          <input
            type="file"
            class="form-control-file"
            accept=".csv"
            @change="pick_file"
          />
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
          :disabled="csv.is_empty()"
          class="w-100"
          >UPLOAD FILE</b-button
        >
      </b-form>
    </div>
    <div v-else>
      <upload-notification
        :notify="notification"
        @clear-file="clear_file"
      ></upload-notification>
    </div>
  </b-modal>
</template>
<script>
import { CsvUpload } from "../upload";
import { BASE_URL } from "./api.constants.js";
import UploadNotification from "../UploadNotification.vue";
export default {
  components: { UploadNotification },
  mixins: [CsvUpload],
  data() {
    return {
      upload_url: BASE_URL+"/:upload_csv",
      csv_url: BASE_URL+"/csv_template",
      csv_name: "farmer_groups_template.csv",
    };
  },
};
</script>