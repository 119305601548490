<template>
  <div>
    <div class="d-flex my-2">
      <h4 class="align-self-end">FARMER GROUPS</h4>
      <b-button squared variant="outline-primary" class="ml-auto" v-b-modal.group-upload>
        <b-icon-file-arrow-up></b-icon-file-arrow-up> Import farmer groups from csv
      </b-button>
      &nbsp;
      <b-button
        variant="primary"
        @click="
          () => {
            this.$bvModal.show('make-group');
          }
        "
        size="md"
      >
        <b-icon-plus> </b-icon-plus>
        &nbsp; New Farmer Group
      </b-button>
    </div>
    <div class="page-controls">
      <b-row class="m-0 w-100">
        <b-col class="pl-0">
          <b-form class="d-inline-block">
            <b-form-input
              placeholder="Search Name"
              v-model="fg_name"
              type="search"
              class="search"
            ></b-form-input>
          </b-form>
        </b-col>
        <b-col cols="auto">
          <div class="sidebar-width text-right">
            <button class="btn-opt primary" @click="reload">
              <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
              Refresh
            </button>
            <button class="btn-opt primary" @click="print">
              <b-icon-printer></b-icon-printer> Print
            </button>
            <download-excel class="btn btn-opt primary" type="csv" :data="items" :fields="groups_csv()">
              <b-icon-book></b-icon-book> Export
            </download-excel>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      :fields="fields"
      :items="items"
      head-variant="info"
      empty-text="No Farmer Groups at this time"
      ref="farmer_groups"
      @row-clicked="(row,idx)=>{$router.push({name:'farm_group',params:{group_id:row.id}})}"
      :filter="fg_name"
      :busy="loading"
      show-empty
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(contact)="data">
        {{ data.item.contact.name }}&nbsp;
        <a :href="'tel:' + data.item.contact.phone_no">
          {{ data.item.contact.phone_no }}
        </a>
      </template>
      <template #cell(produce)="data">
        {{ data.item.produce | format_vchain }}
      </template>
    </b-table>
    <make-group></make-group>
    <group-upload></group-upload>
  </div>
</template>
<script>
import {list_groups} from "./api.calls";
import { bus } from "@/main.js";
import MakeGroup from "./MakeGroup.vue";
import GroupUpload from "./GroupUpload.vue";
import jsPDF from 'jspdf' 
import html2canvas from "html2canvas"
import moment from "moment";
import {groups_csv} from "./ui.utils";
export default {
  components: { MakeGroup, GroupUpload },
  filters:{
    /**
     * @param {Array<string>} produce
     */
    format_vchain:(produce)=>{
      if(produce.length){
        if(produce.length>1){
          return produce[0]+" + "+(produce.length-1).toString()+" more"
        }
        return produce[0];
      }
      return ""
    }
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Name" },
        { key: "contact", label: "Contact" },
        { key: "farmer_count", label: "Farmers" },
        { key: "acres", label: "Acres" },
        { key: "produce", label: "Produce" },
        { key: "date_joined", label: "Date Joined" },
      ],
      items: [],
      fg_name:null,
      groups_csv,
      loading:false,
    };
  },
  computed: {
    projectId() {
      return this.$store.state.project_id;
    },
  },
  mounted() {
    bus.$on("group-added", (data) => {
      this.items.push(data);
    });
    this.list_groups();
  },
  methods: {
    list_groups() {
      this.loading=true;
      list_groups(this.projectId).then(data=>{
        this.loading=false;
        this.items = data;
      })
    },
    reload() {
      this.list_groups();
    },
    print() {
      const elm = this.$refs.farmer_groups;
      const doc = new jsPDF("landscape", "mm", [360, 480]);
      html2canvas(elm).then((canvas) => {
        const img = canvas.toDataURL("image/jpeg");
        doc.addImage(img, "JPEG", 20, 20);
        const today = moment().format("YYYY-MM-DD");
        doc.save("Farmer Groups--" + today + ".pdf");
      });
    },

  },
};
</script>