class FarmerGroup {
    constructor() {
      this.id = ""
      this.name = "";
      this.date_joined = "";
      this.acres = 0.0;
      this.farmer_count = 0;
      this.produce = [];
      this.contact = {
        phone_no: "",
        name: "",
      };
      this.subcounty="";
      this.district="";
    }
}
export {FarmerGroup}