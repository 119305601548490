const groups_csv = ()=>({
    name:"name",
    produce:{
        field:"produce",
        callback:(value)=>{
            if(value.length){
                return value.join("|");
            }
            return ""
        }
    },
    acres:"acres",
    farmer_count:"farmer_count",
    date_joined:"date_joined",
    "contact_phone":"contact.phone_no",
    "contact_name":"contact.name"
})
export {groups_csv}