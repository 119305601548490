<template>
  <b-modal id="make-group" title="New Farmer Group" hide-footer>
    <b-overlay rounded="sm" :show="overlay_show">
      <group-form @save-group="make_group" :group.sync="group"></group-form>
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="stopwatch"
            font-scale="3"
            variant="primary"
            animation="cylon"
            v-show="notify.status == 'creating'"
          ></b-icon>
          <b-icon-check-circle
            variant="success"
            v-show="notify.status == 'created'"
          ></b-icon-check-circle>
          <b-icon-exclamation-circle variant="danger" v-show="notify.show=='failed'">
          </b-icon-exclamation-circle>
          <i>{{ notify.message }}</i>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>
<script>
import { BASE_URL } from "./api.constants.js";
import { axiosApiInst } from "@/plugins/axios.js";
import { bus } from "@/main.js";
import {FarmerGroup} from "./farmer_group";
import GroupForm from "./components/GroupForm.vue"
class notification {
  constructor() {
    this.status = "initialized";
    this.message = "";
    this.fail = (msg) => {
      this.status = "failed";
      this.message = msg;
    };
    this.load = () => {
      this.status = "creating";
      this.message = "";
    };
    this.done = () => {
      this.status = "created";
      this.message = "Group has been created";
    };
    this.refresh = ()=>{
        this.status = "initialized";
        this.message = "";
    }
  }
}
export default {
  components:{GroupForm},
  data() {
    return {
      overlay_show: false,
      notify: new notification(),
      group: new FarmerGroup(),
    };
  },
  computed: {
    projectId() {
      return this.$store.state.project_id;
    },
  },
  methods: {
    async make_group() {
      this.overlay_show = true;
      this.notify.load();
      try {
        const res = await axiosApiInst({
          url: BASE_URL,
          method: "post",
          data: this.group,
        });
        this.notify.done();
        bus.$emit("group-added", res.data);
        setTimeout(()=>{
            this.overlay_show = false;
            this.notify.refresh();
            this.group = new FarmerGroup();
            this.$bvModal.hide("make-group");
        },5000);
      } catch (error) {
        this.notify.fail(error.response.data);
      }
    },
  },
};
</script>