<template>
  <b-form @submit.prevent="save_group">
    <b-form-group label="Name">
      <b-form-input type="text" v-model="group.name" required></b-form-input>
    </b-form-group>
    <b-form-group label="Date Joined">
      <b-form-datepicker
        v-model="group.date_joined"
        required
      ></b-form-datepicker>
    </b-form-group>
    <b-form-group label="Group Agent">
      <b-form-input
        placeholder="Type contact person's name"
        type="text"
        v-model="group.contact.name"
        required
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Phone Number">
      <b-form-input
        type="tel"
        pattern="[0-9]{10}"
        v-model="group.contact.phone_no"
        required
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Subcounty">
      <b-form-input type="text" v-model="group.subcounty"></b-form-input>
    </b-form-group>
    <b-form-group label="District">
      <b-form-select :options="districts" v-model="group.district">
      <template #first>
        <b-form-select-option value="" disabled>-- Please select a district --</b-form-select-option>
      </template>
      </b-form-select>
    </b-form-group>
    <b-button class="w-100" variant="primary" type="submit"
      >Save Farmer Group</b-button
    >
  </b-form>
</template>
<script>
export default {
  props: ["group"],
  computed:{
    districts(){
      let items =this.$store.getters["all_districts"];
      return items;
    }
  },
  methods: {
    save_group() {
      this.$emit("save-group");
    },
  },
};
</script>